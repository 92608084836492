import axios from "axios";
import { print } from "graphql";
import gql from "graphql-tag";
import { useAuth, useAuthUser, useIsAuthenticated } from "@frontegg/nextjs";
import { Integrations } from "@print-engine/utilities/integrations";
import { is_empty, merge } from "@print-engine/utilities/utilities";
import { useRouter } from "next/router";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import Loader from "../global/loader";
import hasuraIntegration from "../_tenantApp/functions/hasuraIntegration";

const IdentityContext = React.createContext();

export const useIdentity = () => useContext(IdentityContext);

/**
 * Request for page comes in.
 * https://app.imprintenginedev.io/?ie_redirect=https://something.com
 *
 * If queryParam "redirect" in url, set cookie, redirect to https://something.com
 *
 * Call useAuthUser() and isAuthenticated() from Frontegg/Nextjs, which automatically
 * redirects user to login page, if user not authenticated.
 *
 * useAuth() automatically redirects.
 * useAuthUser() and useIsAuthenticated() do not.
 */

export function IdentityProvider(user_props = {}) {
    let currentEnvironment =
        process.env.NEXT_PUBLIC_ENVIRONMENT || "development";

    console.log(
        "-Environment name:",
        process.env.NEXT_PUBLIC_ENVIRONMENT,
        "\n-Hasura endpoint:",
        process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT
    );
    let { user: FRONTEGG_user, isAuthenticated } = useAuth(); // NO AUTO REDIRECT NOW; CAN HANDLE MANUALLY

    let allCookies;
    let cookieKeyRegex =
        /ie_redirect=((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    if (FRONTEGG_user && FRONTEGG_user.email) {
        /* LOGGED IN */
        /* User is authenticated.  Check for redirect cookie. */
        allCookies = document?.cookie;
        if (cookieKeyRegex.test(allCookies)) {
            /* REDIRECT */
            /* "redirect" cookie, with valid URL present.  Get value, delete cookie, redirect to value. */
            let redirectUrl = allCookies.match(cookieKeyRegex)[1];
            /* Remove cookie before redirect, so that it's not present for another session. */
            document.cookie = "ie_redirect=;";
            /* Don't allow redirects back to an account/login page */
            let validatedRedirectUrl = redirectUrl.replace(
                /\/account\/login/i,
                ""
            );
            /* Redirect */
            window.location.href = validatedRedirectUrl;
        } else {
        }
    } else {
        /* NOT LOGGED IN */
        if (cookieKeyRegex.test(window.location.search)) {
            /* SET COOKIE AND AUTHENTICATE */
            let redirectUrl = window.location.search.match(cookieKeyRegex)[1];
            document.cookie = `ie_redirect=${redirectUrl};`;
        }
    }

    /* Redirect to account/login AUTOMATICALLY (frontegg/nextjs package) */
    FRONTEGG_user = useAuthUser();
    isAuthenticated = useIsAuthenticated();

    const request = async (qry, vrs) => {
        let xPimHost = /deploy-preview/i.test(window?.location.hostname)
            ? "app.imprintengine.pim"
            : window?.location.hostname;

        let hasuraBaseUrl = process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT;
        console.log(hasuraBaseUrl);
        let config = {
            baseURL: hasuraBaseUrl,
            headers: {
                authorization: `Bearer ${FRONTEGG_user.accessToken}`,
                "x-pim-host": xPimHost,
            },
            timeout: 30000,
            method: "POST",
            data: {
                query: print(
                    gql`
                        ${qry}
                    `
                ),
                variables: vrs,
            },
        };
        console.log(currentEnvironment, "request.config: ", config);
        try {
            let response = await axios(config);
            return response.data.errors ? response.data : response.data.data;
        } catch (e) {
            // throw e;
            console.log("e", qry);
            console.log(e);
            return e;
        }
    };

    let pimUserId;

    try {
        let pimUserMetadata = JSON.parse(FRONTEGG_user.metadata);
        pimUserId = pimUserMetadata?.entity_id;
    } catch (e) {
        console.log(
            "COULDNT PARSE JSON FROM FRONTEGG USER METADATA",
            FRONTEGG_user
        );
    }

    const integrations = useMemo(() => {
        if (isAuthenticated && !is_empty(FRONTEGG_user.accessToken)) {
            const integrations = {
                hasura: {},
            };

            const user_args = {
                environment: currentEnvironment,
                application: "tenant",
                context: "frontend",
                token: FRONTEGG_user.accessToken,
            };

            return new Integrations(integrations, user_args);
        } else {
            return {};
        }
    }, [user_props, FRONTEGG_user, isAuthenticated]);

    const default_props = useMemo(() => {
        return {
            api: {
                integrations: {
                    hasura: integrations.hasura,
                },
            },
        };
    }, [integrations]);

    let props = useMemo(() => {
        return merge(default_props, user_props.props);
    }, [default_props, user_props]);

    const [fetchedUser, setFetchedUser] = useState(null); // 36
    const [currentUser, setCurrentUser] = useState(IdentityContext.user); //37
    const currentApplication = "tenant";
    const currentHost = window?.location.host;
    const router = useRouter();

    const fetchUser = useCallback(
        // USER PARAM = FRONTEGG USER DATA
        async (userParam = {}) => {
            try {
                const response = await request(queryGetUserWhereId, {
                    id: pimUserId,
                });
                let result = response.user;

                if (!result?.email) {
                    setFetchedUser(false);
                    return {};
                }

                if (result.email !== userParam.email) {
                    setFetchedUser(false);
                    return {};
                }

                let authorizedApplications = {
                    tenant: [],
                    client: [],
                };

                if (result.user_entities) {
                    result.user_entities.map((application) => {
                        if (
                            String(
                                application.entity.entity_type.singular_name
                            ).toLowerCase() === "tenant"
                        ) {
                            authorizedApplications.tenant.push(
                                application.entity
                            );
                        }
                        if (
                            String(
                                application.entity.entity_type.singular_name
                            ).toLowerCase() === "customer"
                        ) {
                            authorizedApplications.client.push(
                                application.entity
                            );
                        }
                    });
                }

                result.tenant_user = !is_empty(authorizedApplications.tenant);

                result.authorizedApplications = authorizedApplications;

                setFetchedUser(true);

                let legacyUserObject = merge(
                    userParam,
                    merge(currentUser, result)
                );
                return {
                    ...legacyUserObject,
                    pimUser: result,
                    pimTenantId: result.tenant?.id,
                };
            } catch (error) {
                console.log(error);

                setFetchedUser(false);
            }
        },
        [currentUser, props]
    );

    /** * If the user is authenticated with FrontEgg, then fetch the user from our database.*/
    useEffect(() => {
        (async () => {
            if (isAuthenticated === true && fetchedUser === null) {
                const PIM_user_data = await fetchUser(FRONTEGG_user);

                if (!is_empty(PIM_user_data)) {
                    let _currentUser = merge(
                        merge(currentUser, PIM_user_data),
                        FRONTEGG_user
                    );

                    _currentUser.id = PIM_user_data.id;

                    _currentUser.frontegg_id = FRONTEGG_user.id;

                    const fronteggUserHasAdminRole = (fronteggUser) =>
                        Array.isArray(fronteggUser.roles) &&
                        fronteggUser.roles.some(
                            (role) => role.name === "Admin"
                        );

                    _currentUser.is_super_admin =
                        fronteggUserHasAdminRole(PIM_user_data);

                    setCurrentUser(_currentUser);
                }
            }
        })();
    }, [currentUser, fetchUser, fetchedUser, isAuthenticated, FRONTEGG_user]);

    useEffect(() => {
        (async () => {
            if (!currentEnvironment || !currentApplication) {
                return false;
            }

            if (isAuthenticated === true && fetchedUser === true) {
                // If is valid entity, then redirect to the requested path.
                if (
                    router.query?.path?.[0] &&
                    router.query.path[0].startsWith("ey")
                ) {
                    await router.push(
                        router.asPath.replace(`/${router.query.path[0]}`, "")
                    );
                }

                // If the path is the root of the domain, then redirect to the dashboard.
                if (router.asPath === "/") {
                    await router.push("/dashboard");
                }
            }
        })();
    }, [
        isAuthenticated,
        router,
        // currentEntity,
        currentApplication,
        currentUser,
        currentEnvironment,
        fetchedUser,
    ]);
    if (
        !currentHost ||
        !currentEnvironment ||
        !currentApplication ||
        !currentUser
    ) {
        return <Loader classes="absolute top-5 left-1/2" />;
    }

    let hasura = hasuraIntegration({
        userAccessToken: currentUser?.accessToken,
        environment: currentEnvironment,
        appName: currentApplication,
    }).hasura;
    let appConfig = user_props.props ? user_props.props.app : null;
    let apiConfig = user_props.props ? user_props.props.api : null;
    apiConfig = {
        ...apiConfig,
        integrations: {
            hasura,
        },
    };

    return (
        <IdentityContext.Provider
            value={{
                currentHost,
                currentEnvironment,
                currentApplication,
                // currentEntity,
                currentUser,
                user: currentUser, // @TODO: Remove this once props.user is deprecated.
                request,
                appConfig,
                apiConfig,
            }}
        >
            {user_props.children}
        </IdentityContext.Provider>
    );
}

const queryGetUserWhereId = `query GetUserByPk( $id: bigint! ) {
        user: users_by_pk( id:$id ) {
            id
            first_name
            last_name
            email
            cognito_user_id
            source_ref
            created_at
            updated_at
            active
            tenant {
                id
                name
                tenant_id
            }
            attributes {
                attribute_id
                attribute_name
                attribute_code
                attribute_value
                field_type_id
                field_type
                entity_attribute_id
            },
            user_entities( where: {
                entity: {
                    entity_type: {
                        code: { _in: [ "customer", "tenant", "NETLIFY" ] }
                    }
                }
            } ) {
                id
                entity {
                    id
                    name
                    created_at
                    updated_at
                    active
                    entity_type_id
                    entity_type {
                        id
                        name
                        singular_name
                        plural_name
                    }
                    apps {
                        domain
                        sub_domain
                    }
                }
            }
    }
    }`;
