import Loader from "../components/global/LoaderSvg";
import React, { useMemo } from "react";
import dynamic from "next/dynamic";
import { Integrations } from "@print-engine/utilities/integrations";
import { merge, is_empty } from "@print-engine/utilities/utilities";
import { useIdentity } from "../components/_identityApp/context";

const TenantBackend = dynamic(() =>
    import("../layouts/tenant/backend/index.js")
);

export default function TenantApp(user_props = {}) {
    console.log("___\nRENDER: TenantApp (pages/app.js)");

    const identity = useIdentity();

    const { currentUser, currentEnvironment } = identity;

    const integrations = useMemo(() => {
        let integ = new Integrations(
            {
                hasura: {
                    load: [
                        "orders/orders",
                        "organizations/clients",
                        "projects/projects",
                        "tenants/tenants",
                        "users/users",
                    ],
                },
            },
            {
                environment: currentEnvironment,
                application: user_props["app"]["name"],
                context: "frontend",
                token: currentUser["accessToken"],
            }
        );
        return integ;
    }, [currentUser, user_props]);

    const default_props = {
        api: {
            integrations: {
                hasura: integrations["hasura"],
            },
        },
    };

    let props = useMemo(() => {
        return merge(merge(default_props, user_props), identity);
    }, [default_props, user_props]);

    /**
     *  ex- "/editor/[designId]"
     * WHITE-LABELED
     */
    if (!props.navigation) {
        return <props.component {...props} />;
    }
    return <TenantBackend {...props} />;
}
