var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

/*
https://docs.sentry.io/product/sentry-basics/dsn-explainer/
DSN Utilization
DSNs are safe to keep public because they only allow submission of new events and related event data; they do not allow read access to any information.
*/

Sentry.init({
    dsn:
        SENTRY_DSN ||
        "https://ab4ad27dd0894985acb42eac39b95db9@o567600.ingest.sentry.io/6629552",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    sampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
