import React, { useMemo } from "react";
import Image from "next/image";

export default function Loader(user_props) {
    const default_props = useMemo(() => {
        return {
            styles: {},
            classes: "relative inline-block",
        };
    }, []);

    let props = useMemo(() => {
        return { ...default_props, ...user_props };
    }, [default_props, user_props]);

    return (
        <div className={`loader ${props["classes"]}`} style={props["styles"]}>
            <Image
                quality={100}
                className="icon"
                src="/logo/svg/Icon_2CPurpleBlack_RGB.svg"
                layout="responsive"
                width="2000"
                height="2000"
                alt=""
            />
            <div className="ball" />
        </div>
    );
}
