import "tailwindcss/tailwind.css";
import "../styles/globals.scss";
import "../styles/input-focus-none.css";
import React from "react";
import LoaderSvg from "../components/global/LoaderSvg";
import { FronteggProvider } from "@frontegg/nextjs";
import { IdentityProvider } from "../components/_identityApp/context";
import TenantApp from "./app.js";

const contextOptions = {
    // hostedLoginBox: false,
    hostedLoginBox: true,

    baseUrl:
        process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL ||
        "https://development.auth.imprintengine.io",
    clientId: process.env.NEXT_PUBLIC_FRONTEGG_CLIENT_ID,
};

function App({ Component, pageProps, err }) {
    console.log("contextOptions: ", contextOptions);

    const [proppies, setProppies] = React.useState(null);

    React.useEffect(() => {
        if (/localhost:8888/i.test(window?.location.href)) {
            console.log(
                'YOU ARE AT "localhost:8888/". REDIRECTING YOU TO "http://app.imprintengine.pim:8888/"'
            );
            window.location.href = "http://app.imprintengine.pim:8888/";
        }
        let pathname = window?.location.pathname;
        let hosty = window?.location.host;

        /* TO BYPASS THE DEFAULT "LAYOUT", LEFT-SIDE COLLAPSABLE NAVBAR,
            ADD ROUTE NAME TO FOLLOWING REGEX */
        let removeNavigation = /\/editor\/([0-9]{16})/.test(pathname);

        const { apiUrl, appUrl } = getUrls(hosty);

        let tempProppies = {
            navigation: !removeNavigation,
            host: hosty,
            environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
            component: Component,
            app: {
                url: appUrl,
                component: Component,
                name: "tenant",
                entity_id: 0,
                entity: {},
                router: null,
            },
            api: { url: apiUrl },
        };
        setProppies(tempProppies);
    }, [Component]);

    if (!proppies) {
        return <LoaderSvg />;
    }
    if (!proppies.app) {
        return null;
    }
    if (proppies?.app?.name !== "tenant") {
        return null;
    }
    return (
        <FronteggProvider contextOptions={contextOptions}>
            <IdentityProvider {...proppies} {...pageProps}>
                <TenantApp {...proppies} {...pageProps} />
            </IdentityProvider>
        </FronteggProvider>
    );
}

export default App;

const getUrls = (host) => ({
    /* URL prefix needed for API calls (as is today.  probably can change to relative URLs) */
    apiUrl: `http${/pim/i.test(host) ? "" : "s"}://${host}/api/rest`,
    appUrl: `http${/pim/i.test(host) ? "" : "s"}://${host}`,
});
