export const defaultStyle = {
    width: "50px",
    height: "20px",
    display: "inline",
};

export default function LoaderSvg({ fill = "#000", style = defaultStyle }) {
    return (
        <svg
            style={style}
            x="0px"
            y="0px"
            viewBox="10 10 60 40"
            enableBackground="new 0 0 0 0"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="20" y="20" width="4" height="10" fill={fill}>
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="30" y="20" width="4" height="10" fill={fill}>
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.2s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect x="40" y="20" width="4" height="10" fill={fill}>
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.4s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </rect>
        </svg>
    );
}
