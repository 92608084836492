import { Integrations } from "@print-engine/utilities/integrations";

export default function hasuraIntegration({
    userAccessToken,
    environment,
    appName = "tenant",
}) {
    let hasura = {
        hasura: {
            load: [
                "orders/orders",
                "organizations/clients",
                "projects/projects",
                "users/users",
            ],
        },
    };
    let theRest = {
        environment: environment,
        /* THE FOLLOWING COMMENT IS NO LONGER TRUE, SINCE THIS FILE IS USED _IDENTITY / CONTEXT NOW.  ENABLED APPNAME (TENANT|CLIENT|FOUNTANE,ETC...) */
        /* WE CAN DO THIS SINCE THIS FILE IS IN THE TENANT APP TREE */
        application: appName,
        context:
            "frontend" /* WE CAN DO THIS SINCE THIS FILE IS IN THE TENANT APP TREE */,
        token: userAccessToken,
    };
    let hasuraIntegrationObject = new Integrations(hasura, theRest);
    return hasuraIntegrationObject;
}
